<template>
  <div>
    <Divider dashed style="color:#fff">设置填充内容</Divider>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-20">作业日期</h4>
      <DatePicker size="small" placeholder="选择作业日期" v-model="actionDate" type="date" style="width:100%"
        :editable="false" :clearable="false" @on-change="changeActionDate"></DatePicker>
    </div>
    <div v-if="curDemandType === 7">
      <h4 class="workplatform-title m-t-10 m-b-20">有无画面填充</h4>
      <Checkbox v-model="noPictureFill">无画面填充</Checkbox>
    </div>
    <set-screen :agreeFileArray="fileArray" :selectedResourceIds="selectedResourceIdArray" :curTaskId="curTaskId"
      :selectedTaskitemIds="selectedTaskitemIdArray" :setOperatingType="opreateType" :fileCreateDate="fileDate"
      :isSetFiles="false" :btnSetLoading="btnSetLoading" :isPage="true" @parentMethod="handelSubmit">
      <template #pictureDate>
        <div class="m-b-10">
          <label>素材类型：</label>
          <RadioGroup v-model="materialType" type="button" size="small" @on-change="onChangeMaterialType">
            <Radio v-for="item in materialTypeArray" :key="'m_' + item.value" :label="item.value">{{ item.name }}
            </Radio>
          </RadioGroup>
        </div>
        <Row :gutter="8" class="m-b-10">
          <!-- <i-col span="4">
            <label>素材库：</label>
          </i-col> -->
          <i-col span="12">
            <Select v-model="dateQuery.materialLibrary" size="small" clearable filterable placeholder="选择素材库，可搜索"
              @on-change="getFileDates">
              <Option v-for="item in materialLibList" :key="'mLib_' + item" :value="item">{{ item }}</Option>
            </Select>
          </i-col>
          <i-col span="12">
            <Select v-model="fileDate" size="small" filterable clearable placeholder="选择画面上传日期"
              @on-change="changeFileDate">
              <Option v-for="item in fileDateArray" :key="'f_' + item" :value="item">{{ item }}</Option>
            </Select>
          </i-col>
        </Row>
        <div class="m-b-10">
          <Input size="small" v-model="query.keyword" search enter-button placeholder="可输入画面名称关键字搜索"
            @on-search="onSearchFile" />
        </div>
        <div class="text-center">
          <Page :total="fileTotal" :current="query.pageNumber" size="small" show-total @on-change="onChangePage" />
        </div>
      </template>
    </set-screen>
  </div>
</template>

<script>
import SetScreen from '@/components/task/SetScreen'
import { agreeFileStat, getFileUploadDateList } from '@/api/msp/file'
import { setFillTaskitemFile, updateTaskitemFile } from '@/api/msp/demandV3'
import { getFillType } from '@/api/msp/status'
import { taskMixins } from '../mixins/index'
export default {
  mixins: [taskMixins],
  components: {
    SetScreen
  },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        approvalDate: '',
        approvalId: null,
        fileTypes: '1,3',
        keyword: null,
        status: 5,
        createDate: '',
        type: null,
        materialLibrary: '' // 素材库绑定值
      },
      fileArray: [],
      fileTotal: 0,
      fileDateArray: [],
      fileDate: '',
      actionDate: '',
      materialType: 0,
      materialTypeArray: [
        { value: 0, name: '全部' }
      ],
      dateQuery: {
        type: null,
        materialLibrary: '', // 素材库绑定值
        startDate: '',
        endDate: ''
      },
      btnSetLoading: false,
      noPictureFill: false // 无画面填充
      // materialLibrary: '' // 素材库绑定值
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.task.curTaskId
    },
    curDemandType () {
      return this.$store.state.task.curDemandType
    },
    opreateType () {
      return this.$store.state.task.opreateType
    },
    dataSource () {
      return this.$store.state.task.dataSource
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.task.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    selectedTaskitemIdArray: {
      get () {
        return this.$store.state.task.selectedTaskitemIdArray
      },
      set (val) {
        this.$store.commit('set_selected_taskitemid_array', val)
      }
    }
  },
  created () {
    this.getScreenTypeData()
    this.getFileDates()
    this.getMaterialLibData()
    // this.getFileData()
  },
  methods: {
    getScreenTypeData () {
      getFillType().then(res => {
        if (res && !res.errcode) {
          this.materialTypeArray = this.materialTypeArray.concat(res)
        }
      })
    },
    onChangeMaterialType () {
      const type = this.materialType === 0 ? undefined : this.materialType
      this.dateQuery.materialLibrary = ''
      this.fileDate = ''
      this.fileTotal = 0
      this.query.pageNumber = 1
      this.getMaterialLibData(type)
      this.getFileDates()
    },
    /**
     * 获取素材上传时间集合
     */
    getFileDates () {
      this.dateQuery.type = this.materialType === 0 ? null : this.materialType
      this.fileDate = ''
      this.fileDateArray = []
      getFileUploadDateList(this.dateQuery).then(res => {
        if (res && !res.errcode) {
          this.fileDateArray = res
          // this.fileDate = this.fileDateArray[0]
          this.onSearchFile()
        } else {
          this.fileDateArray = []
        }
      })
    },
    /**
     * 获取已通过审核的画面文件
     */
    getFileData () {
      this.fileArray = []
      this.fileTotal = 0
      this.query.type = this.materialType === 0 ? null : this.materialType
      this.query.createDate = this.fileDate || ''
      this.query.materialLibrary = this.dateQuery.materialLibrary
      agreeFileStat(this.query).then(res => {
        if (res && !res.errcode) {
          this.fileArray = res.fileVOList.list
          this.fileTotal = res.fileVOList.totalRow
          this.query.pageNumber = res.fileVOList.pageNumber
        } else {
          this.fileTotal = 0
          this.query.pageNumber = 1
        }
      })
    },
    onSearchFile () {
      this.query.pageNumber = 1
      this.getFileData()
    },
    onChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getFileData()
    },
    changeActionDate (date) {
      this.actionDate = date
    },
    handelSubmit (data) {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }
      if (data && data instanceof Object) {
        this.btnSetLoading = true
        data.taskId = this.curTaskId
        data.actionDate = this.actionDate

        if (this.opreateType === 1) {
          setFillTaskitemFile(data).then(res => {
            this.btnSetLoading = false
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedResourceIdArray = []
              this.$store.commit('set_temp_selected_item', null)
              if (this.dataSource === 1) {
                this.$store.dispatch('getPublishingTaskitemData')
              } else {
                this.$store.dispatch('getAllDeviceData')
              }
            }
          })
        } else {
          if (this.curDemandType === 7) {
            data = Object.assign({ noPictureFill: this.noPictureFill }, data)
          }
          updateTaskitemFile(data).then(res => {
            this.btnSetLoading = false
            if (res && res.errcode === 0) {
              this.selectedResourceIdArray = []
              this.selectedTaskitemIdArray = []
              this.$store.commit('set_temp_selected_item', null)
              this.$store.dispatch('getTaskitemData')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      } else {
        this.$Notice.error({ desc: '非法请求' })
      }
    },
    changeFileDate () {
      this.onSearchFile()
    }
  }
}
</script>
